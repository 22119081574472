@use 'sass:map';
@use 'sass:color';
@import 'styles/all.scss';

.Footer {
  background-color: #f0efea;
  display: block;
  padding: rem(40px 44px);

  &-container {
    flex: 1;
    display: flex;
    flex-direction: column;
    margin: 0px auto;
    max-width: map.get($breakpoints, 'xxl');
    &__content {
      display: flex;
      gap: rem(48px);

      @include media('<=md') {
        flex-direction: column;
      }

      &-aboutUs {
        margin: 0px auto;
        max-width: rem(450px);

        @include media('<=md') {
          margin: 0;
        }
        &-title {
          font-weight: $font-weight-semibold;
          margin-bottom: rem(8px);
        }

        @include media('<=md') {
          margin: 0;
        }
      }

      &-links {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: $font-size-small;
        gap: rem(14px);
        .ui-kit-button {
          min-width: 0px;
          padding: 0px;
          font-weight: $font-weight-regular;
        }
      }
    }
  }
}
