@use 'sass:map';
@use 'sass:color';
@import 'styles/all.scss';
@import url('https://fonts.googleapis.com/css2?family=Spartan:wght@400;500;600;700&display=swap');

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  display: block;
  margin: 0;
  padding: 0;
}

body {
  font-family: 'Spartan', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  font-size: $font-size-regular;
  font-weight: $font-weight-regular;
  line-height: 1.5;
  letter-spacing: 0px;
  color: $color-body-1;
  display: inherit;
  height: 100%;
  width: 100%;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  .ant-layout {
    min-height: 100%;
    background-color: $color-white;
    &.ant-layout-has-sider {
      flex-direction: column;
    }
  }

  .ant-select:not(.ant-select-customize-input) {
    .ant-select-selector {
      text-align: left;
      height: rem(42px);
      padding: rem(6px 14px);
      .ant-select-selection-search-input {
        height: 100%;
        font-size: $font-size-caption;
      }
      .ant-select-selection-item {
        font-size: $font-size-caption;
      }
    }
  }

  .ant-modal-mask {
    backdrop-filter: blur(15px);
    background-color: color.change(map.get($colors, 'textLight', 'light'), $alpha: 0.5);
  }

  .ant-divider-horizontal.ant-divider-with-text {
    margin: 0px;
    &::before,
    &::after {
      border-top-color: map.get($colors, 'textLight', 'light');
    }
  }
}

#root {
  height: 100%;
  width: 100%;
  .ant-spin-nested-loading {
    height: 100%;
    & .ant-spin.ant-spin-spinning {
      max-height: 100%;
    }
  }

  .ant-spin-container {
    height: inherit;
  }
}

.app {
  width: 100%;
  height: 100%;
}

// a {
//   color: map.get($colors, 'primary', 'main');
//   font-weight: $font-weight-semibold;
//   font-size: inherit;

//   &:visited,
//   &:active {
//     color: map.get($colors, 'primary', 'dark');
//   }

//   &:hover {
//     &:visited {
//       color: map.get($colors, 'primary', 'main');
//     }
//     color: map.get($colors, 'primary', 'dark');
//   }
// }

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

h1 {
  font-size: rem(96px);
  font-weight: $font-weight-light;
  line-height: 1.167;
}

h2 {
  font-size: rem(60px);
  font-weight: $font-weight-light;
  line-height: 1.2;
}

h3 {
  font-size: rem(48px);
  font-weight: $font-weight-regular;
  line-height: 1.167;
}

h4 {
  font-size: rem(34px);
  font-weight: $font-weight-regular;
  line-height: 1.235;
}

h5 {
  font-size: rem(24px);
  font-weight: $font-weight-regular;
  line-height: 1.334;
}

h6 {
  font-size: rem(20px);
  font-weight: $font-weight-regular;
  line-height: 1.6;
}
